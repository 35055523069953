<template>
  <div class="flex flex-col justify-center items-center">
    <i :class="`fi fi-rr-${icon} ${iconcolor} text-3xl`"></i>
    <span :class="`${textcolor} text-sm capitalize`">{{text}}</span>
  </div>
</template>

<script>
export default {
    name: "IconTextDisplay",
    props: {
        icon: String,
        text: String,
        iconColor: String,
        textColor: String
    },
    computed: {
      iconcolor() {
        return this.iconColor != null ? this.iconColor : "text-green-800";
      },
      textcolor() {
        return this.textColor != null ? this.textColor : "text-gray-800";
      }
    }
};
</script>

<style></style>
