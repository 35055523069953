<template>
  <!-- landing screen -->
  <br />
  <Section
    class="
      flex flex-col
      justify-center
      md:flex-row md:justify-between
      items-center
      px-5
      md:px-36
      lg:px-64
      pt-16
    "
    v-smartnav
  >
    <div class="w-full md:w-1/2 h-full relative mt-10 md:mt-0">
      <br />
      <div class="w-9/12 h-72 absolute bottom-1/3 left-1/4 z-0">
        <img
          src="../assets/images/ecd.jpeg"
          alt=""
          class="h-full object-cover"
        />
      </div>
      <div
        class="
          w-10/12
          md:w-full
          h-28
          shadow-xl
          border-8 border-white
          absolute
          top-1/2
          left-11
          z-0
        "
      ></div>
      <div class="w-9/12 h-72 shadow-xl bg-green-800 z-30"></div>
      <br /><br />
    </div>

    <div class="w-full md:w-1/2 md:ml-20 text-left">
      <h4 class="font-bold text-4xl capitalize text-green-800 leading-tight">
        About Us
      </h4>
      <br />

      <p class="text-gray-800 text-justify">
        Rights Institute for Social Empowerment (RISE) is a women’s rights
        organization with a deliberate mandate to promote women and girls’
        rights. Rights Institute for Social Empowerment (RISE) is a Women Rights
        Organization (WRO) dedicated to the advancement of equality, promotion
        of social justice and the respect of human dignity for women, girls
        including LBTI women and those living with HIV and disabilities.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        RISE uses gender transformative and feminist approaches in project
        design and implementation. RISE demonstrates accountability and
        centering of women and girls. Girls and women are involved during
        project design and implementation.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        As a National WRO, RISE adopts an explicit feminist approach to project
        design. Girls and women are involved for them to share their real needs,
        their suggested solutions and their aspirations as to how they want
        interventions to improve their lives.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        Advocacy and direct empowerment of women, girls, young people, children
        and their communities by building their capabilities so that they are
        able to rise up to the consciousness and capacity of advocating for the
        promotion of their human rights, protect themselves and be protected
        from human rights of all forms and be able to actively participate in
        the governance, policy formulation and decision making processes
        including democratization in Malawi.
      </p>
    </div>
  </Section>

  <div class="px-5 md:px-36 lg:px-64 text-justify md:text-center mt-4 md:mt-0">
    <div class="w-full flex flex-col md:flex-row md:justify-between">
      <p class="text-gray-800 text-justify md:mr-4">
        Registered in 2006 as a Civil Society Organization, RISE is focusing on
        the promotion and protection of human rights for marginalized social
        groups in Malawi. Our approach is human rights based approach to
        programming and implementing INTERVENTIONS. Our projects are benefiting
        the most vulnerable and socially excluded members of the Malawi society
        including women, girls and children.
      </p>

      <p class="text-gray-800 text-justify md:ml-4 mt-4 md:mt-0">
        RISE core business is the protection of fundamental human rights for
        marginalized people made more vulnerable by human rights violations,
        HIV/AIDS, poverty and other forms of social injustices through advocacy,
        capacity building, direct service provision, collaboration, awareness
        raising and participatory human rights education provision.
      </p>
    </div>
  </div>

  <!-- Values -->
  <br />
  <br />
  <div class="values-parallax">
    <div
      class="
        w-full
        h-full
        backdrop-filter backdrop-blur-md
        bg-black bg-opacity-30
        py-5
        px-10
        md:px-36
        lg:px-64
      "
    >
      <p class="mb-10 text-center text-3xl font-semibold text-white">
        <!-- <i class="fi fi-rr-feather relative top-1 text-green-600"></i> -->
        Our Values
      </p>
      <div class="flex flex-col md:flex-row justify-between items w-full">
        <IconTextDisplay
          icon="users"
          text="Respect for all genders and cultures."
          iconColor="text-white"
          textColor="text-gray-200"
        />
        <IconTextDisplay
          icon="thumbs-up"
          text="Non discrimination."
          class="mt-5 md:mt-0"
          iconColor="text-white"
          textColor="text-gray-200"
        />
        <IconTextDisplay
          icon="world"
          text="Diversity."
          class="mt-5 md:mt-0"
          iconColor="text-white"
          textColor="text-gray-200"
        />
        <IconTextDisplay
          icon="physics"
          text="Creativity and voluntarism."
          class="mt-5 md:mt-0"
          iconColor="text-white"
          textColor="text-gray-200"
        />
      </div>
    </div>
  </div>

  <Section class="px-5 md:px-36 lg:px-64 text-justify md:text-center">
    <!-- Mission -->
    <br /><br /><br />
    <p class="mb-10 text-center text-3xl font-semibold text-green-800">
      <!-- <i class="fi fi-rr-rocket relative top-1 text-green-600"></i> -->
      Our Mission
    </p>
    <p class="text-gray-800">
      RISE Malawi main purpose is promotion and protection of human rights for
      marginalized women and girls to ensure they are protected from all forms
      of Gender Based Violence, access Sexual and Reproductive Health
      Information, services and resources and empowered to actively participate
      in the political and economic spheres.
    </p>

    <!-- Mandate -->
    <br /><br /><br />
    <p class="mb-10 text-center text-3xl font-semibold text-green-800">
      <!-- <i class="fi fi-rr-users relative top-1 text-green-600"></i> -->
      Our Mandate
    </p>
    <p class="text-gray-800">
      RISE mandate is creating individual and community environments where the
      rights of women and girls will be protected and where women and girls will
      achieve universal access to SRHR, including family planning, adolescents
      sexuality education, safe childbirth, safe abortion, HIV and SRHR in
      emergencies.
    </p>

    <!-- Vision -->
    <br /><br /><br />
    <p class="mb-10 text-center text-3xl font-semibold text-green-800">
      <!-- <i class="fi fi-rr-eye relative top-1 text-green-600"></i> -->
      Our Vision
    </p>
    <p class="text-gray-800">
      RISE’s vision is a society where women and girls are free from all forms
      of VAWG and have universal access to SRHR, safe childbirth and safe
      abortion resources and services. The RISE vision ensures that RISE is
      centrally focused on women’s rights, that it works from a women’s rights
      perspective and focused on improving women’s social, economic and
      political empowerment by supporting women-led civil society.
    </p>
    <br />
    <br />
  </Section>
</template>
<script>
import Section from "@/components/Section";
import IconTextDisplay from "@/components/IconTextDisplay";

export default {
  name: "About",
  components: {
    Section,
    IconTextDisplay,
  },
};
</script>
<style scoped>
.values-parallax {
  /* The image used */
  background-image: url("../assets/images/children.jpg");

  /* Set a specific height */
  height: 30vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 425px) {
  .values-parallax {
    height: 60vh;
  }
}
</style>
